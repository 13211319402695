<template>
  <div style="height: 99%">
    <iframe
      id="chartFrame"
      :src="url"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {which} from "../../package.json"
import chartListener from '../mixins/chartListener'
var enableStreaming = false
var updatedResponseFeed = {}
var data = null
export default {
  mixins:[chartListener],
  data() {
    return {
      url: null,
      currentData: '',
      appendUrl: "http://localhost:8081/chartFrame/?data=",
      depolyUrl: which == 'Live' ? "https://abmtradelite.adityabirlamoney.com/chartFrame/?data=" : "https://connectuat.adityabirlamoney.com/chartFrame/?data=", 
      tokenList: []
    };
  },
  computed: {
    ...mapGetters("authentication", ["getUserId", "getUserSession"]),
  },
  methods: {
  // listenMwTicks(event){
  //     let tickerData = event.detail.storage
  //     let stream = document.getElementById("chartFrame")
  //     let message = {
  //       id : 'stream',
  //       message: tickerData
  //     }
  //     stream && stream.contentWindow ? stream.contentWindow?.postMessage(message, "*") : ''
  //   },
    refresh(item) {
      
      this.tokenList = []
      var currentChart = "";
      let getLocal = JSON.parse(localStorage.getItem("chartData"));
      let theme = localStorage.getItem('dark_theme') ? localStorage.getItem('dark_theme') : "false"
      item.data == "initial"
        ? (currentChart = getLocal["data"])
        : (currentChart = item.data);
      localStorage.setItem("chartData", JSON.stringify(getLocal));
      getLocal = JSON.parse(localStorage.getItem("chartData"));
      var token = currentChart.token;
      var exchange = currentChart.chartSymbol.includes('::INDEX') ? `${currentChart.ex}::index` :currentChart.ex;
      var symbol = currentChart.chartSymbol.split("::")[0];
      let indicators = this.$store.state.chartIndicators
      data = {
        symbol: symbol,
        interval: "5",
        token: token,
        exchange: exchange,
        clientId: "ABML",
        userId: this.getUserId,
        locale: "en",
        widgetbar: {
          details: false,
          watchlist: false,
          watchlist_settings: { default_symbols: [] },
        },
        timeFrames: [
          { text: "5y", resolution: "1W" },
          { text: "1y", resolution: "1W" },
          { text: "6m", resolution: "120" },
          { text: "3m", resolution: "60" },
          { text: "1m", resolution: "30" },
          { text: "5d", resolution: "5" },
          { text: "1d", resolution: "1" },
        ],
        uid: "tradingview_cf3ee",
        chartsStorageUrl: which == "Live" ? "https://abmtradelite.adityabirlamoney.com/rest/ABMLChartAPIService" : "https://connectuat.adityabirlamoney.com/rest/ABMLChartAPIService",
        chartsStorageVer: "1.1",
        autoSaveDelay: 5,
        debug: false,
        timezone: "Asia/Kolkata",
        session: this.getUserSession,
        loginType:"web",
        showPopout: true,
        isSymbolSearch: false,
        theme: theme == "true"  ? 'dark' : 'light',
        which: which,
        preloadProps : {
            interval: "1D",
            indicators: indicators,
          }
      };
      this.$store.commit('setTardeData' , data)
      this.currentData = data
      let destination = document.getElementById("chartFrame");
      // if(destination.src  == window.location.href)
      if (destination && destination.contentWindow && destination.src) {
        let message = {
          id : 'changeSymbol',
          message: "?data="+JSON.stringify(data)
        }
        destination.contentWindow?.postMessage(message, "*");
      } else {
         let tempUrl = window.location.protocol == 'https:' ? this.depolyUrl : this.appendUrl
        this.url = tempUrl + JSON.stringify(data)
        this.$store.commit("chartHandle/setRefreshArray", destination)

      }
    },
  },
  mounted() {
    let getLocal = JSON.parse(localStorage.getItem("chartData"));
    if (getLocal) {
      let temp = {
        data: "initial",
        where: "chart",
      };
      this.refresh(temp);
    }

    // let checkStream = document.getElementById('chartFrame')
    // let tickers = JSON.parse(window.localStorage.getItem('tickers'))
    // let message = {
    //   id: 'stream',
    //   message: tickers
    // }

    // setTimeout(() => {
    //   if (checkStream && checkStream.contentWindow) {
    //     checkStream.contentWindow.postMessage(message, "*")
    //   }
    // }, 500);
    
    // window.addEventListener('tickers-changed' , this.listenMwTicks)
  },

  created: function () {
    this.$root.$refs.TVChartContainer = this;
  },
};
</script>